export const EDIT_PAGE_EMBED = {
	name: 'EDIT_PAGE_EMBED',
	pattern: '/wiki/spaces/:spaceKey/pages/edit-embed/:contentId/:contentSlug?',
};

export const EDIT_PAGE_V2 = {
	name: 'EDIT_PAGE_V2',
	pattern: '/wiki/spaces/:spaceKey/pages/edit-v2/:contentId/:contentSlug?',
};

export const SPACE_OVERVIEW = {
	name: 'SPACE_OVERVIEW',
	pattern: '/wiki/spaces/:spaceKey/overview',
};

export const VIEW_PAGE = {
	name: 'VIEW_PAGE',
	pattern: '/wiki/spaces/:spaceKey/pages/:contentId/:contentSlug?',
};

export const VIEW_BLOG = {
	name: 'VIEW_BLOG',
	pattern: '/wiki/spaces/:spaceKey/blog/:contentId/:contentSlug?',
};

export const VIEW_BLOG_DATE_LEGACY = {
	name: 'VIEW_BLOG_DATE_LEGACY',
	pattern: '/wiki/spaces/:spaceKey/blog/:year/:month/:day/:contentId/:contentSlug?',
};

export const WHITEBOARD = {
	name: 'WHITEBOARD',
	pattern: '/wiki/spaces/:spaceKey/whiteboard/:contentId/:contentSlug?',
};

export const namedRoutes: {
	[name: string]: { name: string; pattern: string };
} = {
	EDIT_PAGE_EMBED,
	EDIT_PAGE_V2,
	SPACE_OVERVIEW,
	VIEW_PAGE,
	VIEW_BLOG,
	VIEW_BLOG_DATE_LEGACY,
	WHITEBOARD,
};

export const EMBEDDED_PARENT_PRODUCTS = {
	JSM_PORTAL: 'JSM-Portal',
	JSM: 'JSM',
	JSW: 'JSW',
	JWM: 'JWM',
};
