import { matchPath } from 'react-router-dom';

import { type ThemeState, themeStringToObject } from '@atlaskit/tokens';

import type { PageProps, ParsedPageProps } from './PageProps';
import type { PageAllowedFeatures, ParsedPageAllowedFeatures } from '../features';
import { DEFAULT_ALLOWED_FEATURES } from '../features';
import { EMBEDDED_CONFLUENCE_MODE } from '../page-common/EmbeddedConfluenceMode';
import {
	EDIT_PAGE_EMBED,
	EDIT_PAGE_V2,
	VIEW_PAGE,
	VIEW_BLOG,
	VIEW_BLOG_DATE_LEGACY,
	WHITEBOARD,
	namedRoutes,
} from '../page-common/routes';

type ParsedUrlProps = {
	route: string;
	hostname?: string;
	protocol: string;
	contentId: string;
	spaceKey?: string;
	hash?: string;
	parentProduct: string;
	draftShareId?: string;
	themeState?: string;
	contentSlug?: string;
	query: { [key: string]: string | string[] | undefined };
};

type UrlMatch = {
	url: string;
	protocol: string;
	host: string;
	pathname: string;
	search: string;
	hash: string;
	pattern: string;
	params: { [key: string]: string };
	query: { [key: string]: string | string[] | undefined };
	themeState?: string;
};

export function parsePageProps(props: PageProps): ParsedPageProps {
	let {
		contentId = '',
		hostname,
		parentProduct = '',
		spaceKey,
		url,
		allowedFeatures,
		mode,
		draftShareId,
		hash,
		themeState,
		...passThroughProps
	} = props;

	const parsedAllowedFeatures = parseAllowedFeatures(allowedFeatures);

	if (!url) {
		return {
			contentId,
			hostname,
			parentProduct,
			spaceKey,
			protocol: 'https:',
			mode: mode ?? EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
			allowedFeatures: parsedAllowedFeatures,
			draftShareId,
			hash,
			themeState,
			...passThroughProps,
		};
	}

	const parsedProps = parseUrl(url);

	if (!parsedProps) {
		return {
			hostname,
			contentId,
			spaceKey,
			parentProduct,
			mode,
			protocol: 'https:',
			allowedFeatures: parsedAllowedFeatures,
			draftShareId,
			hash,
			themeState,
			...passThroughProps,
		};
	}

	const themeStateParsedObject = parsedProps.themeState
		? themeStringToObject(decodeURIComponent(parsedProps.themeState))
		: undefined;

	// Override with URL-extracted props
	contentId = contentId || parsedProps.contentId;
	parentProduct = parentProduct || parsedProps.parentProduct || '';
	hostname = hostname || parsedProps.hostname || window.location.hostname;
	spaceKey = spaceKey || parsedProps.spaceKey;
	draftShareId = draftShareId || parsedProps.draftShareId;
	hash = hash || parsedProps.hash;
	themeState = themeState || (themeStateParsedObject as ThemeState);

	let modeFromUrl;
	switch (parsedProps.route) {
		case VIEW_PAGE.name:
		case VIEW_BLOG.name:
		case VIEW_BLOG_DATE_LEGACY.name:
			modeFromUrl = EMBEDDED_CONFLUENCE_MODE.VIEW_MODE;
			break;
		case EDIT_PAGE_EMBED.name:
		case EDIT_PAGE_V2.name:
			modeFromUrl = EMBEDDED_CONFLUENCE_MODE.EDIT_MODE;
			break;
		case WHITEBOARD.name:
	}

	const contentSlug =
		parsedProps.route === VIEW_BLOG_DATE_LEGACY.name ? undefined : parsedProps.contentSlug;

	return {
		allowedFeatures: parsedAllowedFeatures,
		contentId,
		draftShareId,
		hash,
		hostname,
		mode: mode || modeFromUrl,
		parentProduct,
		protocol: parsedProps.protocol,
		spaceKey,
		themeState,
		contentSlug,
		...passThroughProps,
	};
}

/**
 * @deprecated This is a temporary API function as ViewPage and EditPage components will
 * support url prop soon. See CBT-954 for more information
 */
export function parseUrl(url: string | undefined): ParsedUrlProps | null {
	if (!url) {
		return null;
	}

	const matchedRoute = findAndMatchRoute(namedRoutes, url);

	if (!matchedRoute) {
		return null;
	}

	const { routeName, ...match } = matchedRoute;

	const { year, month, day, contentId } = match.params;

	// Validate date parameters
	const isValidYear = /^\d+$/.test(year || '');
	const isValidMonth = /^\d+$/.test(month || '');
	const isValidDay = /^\d+$/.test(day || '');
	const isValidContentId = /^\d+$/.test(contentId || '');

	// If route requires validation, and any parameter is invalid, return null
	if (
		routeName === VIEW_BLOG_DATE_LEGACY.name &&
		(!isValidYear || !isValidMonth || !isValidDay || !isValidContentId)
	) {
		return null;
	}

	return {
		route: routeName,
		hostname: match.host,
		protocol: match.protocol,
		contentId: contentId || '',
		spaceKey: match.params.spaceKey || '',
		hash: match.hash || '',
		// EP currently supports only 1 parent product, no more than 1 parent product should be passed in
		parentProduct: Array.isArray(match.query.parentProduct)
			? match.query.parentProduct[0]
			: match.query.parentProduct || '',
		draftShareId: Array.isArray(match.query.draftShareId)
			? match.query.draftShareId[0]
			: match.query.draftShareId,
		themeState: match.query.themeState as string,
		contentSlug: match.params.contentSlug || undefined,
		query: match.query,
	};
}

function findAndMatchRoute(
	routes: typeof namedRoutes,
	url: string,
): ({ routeName: string } & UrlMatch) | null {
	for (const routeKey of Object.keys(routes)) {
		const route = routes[routeKey];
		const match = matchUrl(route.pattern, url);
		if (match) {
			return { routeName: route.name, ...match };
		}
	}
	return null;
}

function isValidURL(url: string): boolean {
	try {
		new URL(url, window.location.origin);
		return true;
	} catch {
		return false;
	}
}

function matchUrl(pattern: string, url: string): UrlMatch | null {
	if (!isValidURL(url)) {
		return null;
	}

	const parsed = new URL(url, window.location.origin);

	const pathname: string = parsed.pathname || '';
	const search: string = parsed.search || '';

	let match = matchPath({ path: pattern, end: true }, pathname);

	// If initial match fails and the route requires query parameters, attempt to match including search
	// some of our routes need query params to match
	if (!match && search) {
		match = matchPath({ path: pattern, end: true }, `${pathname}${search}`);
	}

	if (!match) {
		return null;
	}

	// URI decode params
	const decodedParams: { [key: string]: string } = {};
	if (match.params) {
		for (const [key, value] of Object.entries(match.params)) {
			if (value !== undefined) {
				decodedParams[key] = value;
			}
		}
	}

	return {
		url,
		protocol: parsed.protocol,
		host: parsed.host,
		pathname,
		search: parsed.search,
		hash: parsed.hash,
		pattern,
		params: decodedParams,
		query: Object.fromEntries(parsed.searchParams.entries()),
	};
}

export function parseAllowedFeatures(
	allowedFeatures?: PageAllowedFeatures,
): ParsedPageAllowedFeatures {
	let parsedAllowedFeatures: ParsedPageAllowedFeatures = {
		view: allowedFeatures?.view,
		edit: allowedFeatures?.edit,
	};

	if (!allowedFeatures?.view) {
		parsedAllowedFeatures.view = [...DEFAULT_ALLOWED_FEATURES.view, 'edit'];
	} else if (Array.isArray(allowedFeatures.view)) {
		parsedAllowedFeatures.view = [...allowedFeatures.view, 'edit'];
	}

	return parsedAllowedFeatures;
}
